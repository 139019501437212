import React, { useEffect, useState } from 'react';
import client from '../../sanityClient';
import './about.css';

function About() {
  const [featuredPosts, setFeaturedPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "aboutFeatured"]{
          featuredByName,
          featuredByDate,
          featuredByLink
        }`
      )
      .then((data) => setFeaturedPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div className="About">
      <div className="about-cover-img-container">
        <img src="/main.webp" alt="Main Cover" />
        <div className="title-one about-cover-title">Luka Kolanovic</div>
      </div>
      <div className="about-content">
        <div className="about-section">
          <div className='about-section-title title-two big-margin'>Featuring my Work</div>
          <div className='about-section-feat'>
            {featuredPosts.map((post, index) => (
              <React.Fragment key={index}>
                <div className="about-section-feat-content">
                  <a 
                    href={post.featuredByLink} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="feat-name text-regular text-is-gray no-margin"
                  >
                    {post.featuredByName}
                  </a>
                  <div className="feat-date text-regular text-is-gray no-margin">
                    {new Date(post.featuredByDate).getFullYear()}
                  </div>
                </div>
                <div className="line-is-gray"></div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
