import React, { useEffect, useState } from 'react';
import './cursor.css';

function Cursor() {
    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [targetX, setTargetX] = useState(0);
    const [targetY, setTargetY] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const hoverClasses = ['menu-button', 'img-nav-filter', 'text-regular', 'divider', 'is-link', 'thumbnail-img'];

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        console.log(userAgent);
        if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
            setIsMobile(true);
        }
    }, []);

    useEffect(() => {
        if (isMobile) return;

        let animationFrameId;

        const handleMouseMove = (e) => {
            setTargetX(e.clientX);
            setTargetY(e.clientY);
        };

        let smoothAmount = 0.45;

        const updateCursorPosition = () => {
            setCursorX((prevX) => prevX + (targetX - prevX) * smoothAmount);
            setCursorY((prevY) => prevY + (targetY - prevY) * smoothAmount);
            animationFrameId = requestAnimationFrame(updateCursorPosition);
        };

        const handleMouseEnter = (e) => {
            if (hoverClasses.some(className => e.target.classList.contains(className))) {
                setIsHovered(true);
            }
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        document.body.addEventListener('mousemove', handleMouseMove);
        document.body.addEventListener('mouseenter', handleMouseEnter, true);
        document.body.addEventListener('mouseleave', handleMouseLeave, true);

        animationFrameId = requestAnimationFrame(updateCursorPosition);

        return () => {
            document.body.removeEventListener('mousemove', handleMouseMove);
            document.body.removeEventListener('mouseenter', handleMouseEnter, true);
            document.body.removeEventListener('mouseleave', handleMouseLeave, true);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isMobile, targetX, targetY]);

    if (isMobile) return null;

    return (
        <div className={`cursor ${isHovered ? 'hovered' : ''}`}
            style={{
                left: `${cursorX}px`,
                top: `${cursorY}px`,
                position: 'fixed',
                pointerEvents: 'none',
                transform: `translate(-50%, -50%)`,
                width: `${isHovered ? '70px' : '15px'}`,
                height: `${isHovered ? '70px' : '15px'}`,
                transition: 'width 0.25s ease, height 0.25s ease, transform 0.5s ease'
            }}
        />
    );
}

export default Cursor;
