import React, { useEffect, useRef, useState } from 'react';
import './img-nav.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function ImgNavBar({ onFilterChange }) {
    const [activeFilter, setActiveFilter] = useState('all');
    const filters = ['all', 'sports', 'politics', 'event'];
    const activeBoxRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const activeIndex = filters.indexOf(activeFilter);
        const activeFilterElement = containerRef.current.children[activeIndex + 1]; // +1 für die activeBox

        const filterRect = activeFilterElement.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const boxPosition = filterRect.left - containerRect.left;
        const boxWidth = filterRect.width;

        gsap.to(activeBoxRef.current, {
            x: boxPosition,
            width: boxWidth,
            duration: 0.35,
            ease: 'back.out(0.5)',
        });
    }, [activeFilter]);

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        onFilterChange(filter);
    };

    return (
        <div className="img-nav-bar">
            <div className="img-nav-container" ref={containerRef}>
                <div className="img-nav-active-box" ref={activeBoxRef} />
                {filters.map((filter) => (
                    <div
                        key={filter}
                        className={`img-nav-filter ${activeFilter === filter ? 'img-nav-filter-active' : ''}`}
                        onClick={() => handleFilterClick(filter)}
                    >
                        {filter}
                    </div>
                ))}
            </div>
        </div>
    );



}

export default ImgNavBar;
