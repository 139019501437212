import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import './loading-screen.css';

const LoadingScreen = () => {
    const containerRef = useRef(null);
    const progressBarRef = useRef(null);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const loadSimulation = () => {
            return new Promise((resolve) => {
                let loaded = 0;
                let increment = 0;
                const interval = setInterval(() => {
                    increment += 0.05;
                    loaded = Math.floor(100 * Math.pow(increment, 2));

                    if (loaded > 100) {
                        loaded = 100;
                    }

                    setProgress(loaded);

                    if (loaded >= 100) {
                        setTimeout(() => {
                            clearInterval(interval);
                            resolve();
                        }, 1000);
                    }
                }, 100);
            });
        };

        loadSimulation().then(() => {
            gsap.to(containerRef.current, { yPercent: 100, duration: 1, ease: 'power2.inOut' });
            gsap.to(progressBarRef.current, { opacity: 0, duration: 0.5 });
        });

        gsap.set(containerRef.current, { yPercent: 0 });
    }, []);

    return (
        <div ref={containerRef} className="loading-container">
            <div className="loading-progress">{progress}%</div>
            <div className="loading-bar-container">
                <div ref={progressBarRef} className="loading-bar" style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

export default LoadingScreen;
