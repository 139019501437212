import './home.css';
import ImgNavBar from '../../components/img-nav/img-nav';
import ImgGrid from '../../components/img-grid/img-grid';

function Home() {

  return (
    <div className="App">
      <ImgNavBar />
      <div className="container">
        <div className="content">
          <div className="content-title">
            <div className='title-one'>
              Luka Kolanovic
            </div>
          </div>
        </div>
      </div>
      <ImgGrid />
    </div>
  );
}

export default Home;
