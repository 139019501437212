import sanityClient from '@sanity/client';

const client = sanityClient({
  projectId: "ne1coapi",
  dataset: "production",
  apiVersion: "2024-01-01",
  useCdn: false,
});

export default client;
