
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Lenis from 'lenis';
import Menu from './components/menu/menu';
import Home from './pages/Home/home';
import Work from './pages/Work/work';
import About from './pages/About/about';
import Transition from './components/transition/transition';
import LoadingScreen from './components/loading-screen/loading-screen';
import Cursor from './components/cursor/cursor';
import GalleryDetail from './components/gallery-detail/gallery-detail';

function App() {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const location = useLocation();

    const lenis = new Lenis();

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    useEffect(() => {
        setIsTransitioning(true);
        const timer = setTimeout(() => {
            setIsTransitioning(false);
        }, 1000); // Gesamtzeit für den Übergang

        return () => clearTimeout(timer);
    }, [location]);

    return (
        <>
            <Cursor />
            <Menu />
            <LoadingScreen />
            <Transition isTransitioning={isTransitioning}>
                <Routes location={location}>
                    <Route path="/" element={<Home />} />
                    <Route path="/work" element={<Work />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/gallery/:slug" element={<GalleryDetail />} />
                </Routes>
            </Transition>
        </>
    );
}

function WrappedApp() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default WrappedApp;
