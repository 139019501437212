// transition.jsx
import React, { useEffect, useState } from 'react';
import './transition.css';

const Transition = ({ isTransitioning, children }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [fadeClass, setFadeClass] = useState('');

    useEffect(() => {
        if (isTransitioning) {
            setFadeClass('fade-out');
            const timer = setTimeout(() => {
                setIsVisible(false);
                setFadeClass('');
            }, 500);

            return () => clearTimeout(timer);
        } else {
            if (!isVisible) {
                setFadeClass('fade-in');
                const timer = setTimeout(() => {
                    setIsVisible(true);
                }, 0);

                return () => clearTimeout(timer);
            }
        }
    }, [isTransitioning, isVisible]);

    return (
        <div className={`transition-container ${fadeClass}`} style={{ display: isVisible ? 'block' : 'none' }}>
            {children}
        </div>
    );
};

export default Transition;
