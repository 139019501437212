import React, { useEffect, useRef, useState } from 'react';
import './menu.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useLocation } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

function Menu() {
    const navMenuRef = useRef(null);
    const menuButtonRef = useRef(null);
    const closeButtonRef = useRef(null);
    const menuTl = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navBarTitleRef = useRef(null);
    const navTextAnimationRefs = useRef([]);
    const location = useLocation();

    useEffect(() => {
        const handleMouseOver = (ref) => {
            gsap.to(ref, {
                x: 10,
                duration: 0.25,
                ease: "power2.inOut",
            });
        };

        const handleMouseOut = (ref) => {
            gsap.to(ref, {
                x: 0,
                duration: 0.25,
                ease: "power2.inOut",
            });
        };

        navTextAnimationRefs.current.forEach(ref => {
            if (ref) {
                ref.addEventListener('mouseover', () => handleMouseOver(ref));
                ref.addEventListener('mouseout', () => handleMouseOut(ref));
            }
        });

        return () => {
            navTextAnimationRefs.current.forEach(ref => {
                if (ref) {
                    ref.removeEventListener('mouseover', () => handleMouseOver(ref));
                    ref.removeEventListener('mouseout', () => handleMouseOut(ref));
                }
            });
        };
    }, []);

    useEffect(() => {
        const navMenu = navMenuRef.current;
        const navBarTitle = navBarTitleRef.current;
        const menuButton = menuButtonRef.current;
        const closeButton = closeButtonRef.current;

        gsap.to(navBarTitle, {
            y: -10,
            opacity: 0,
            duration: 0.5,
            stagger: 0.1,
            scrollTrigger: {
                trigger: navBarTitle,
                scrub: false,
                start: "bottom+=50",
                end: "bottom+=100",
                toggleActions: "play none none reverse",
            },
        });

        gsap.set(navMenu, {
            x: "100%",
        });

        gsap.set(closeButton, {
            y: "20px",
        });

        menuTl.current = gsap.timeline({ paused: true })
            .to(menuButton, {
                y: "40px",
                duration: 0.25,
                ease: "power2.inOut",
            })
            .to(navMenu, {
                clipPath: "circle(125% at 100% 50%)",
                duration: 1,
                x: 0,
                ease: "power2.inOut",
            })
            .fromTo(navTextAnimationRefs.current, {
                x: "100%",
            }, {
                x: 0,
                delay: -0.5,
                ease: "back.out(0.5)",
                stagger: {
                    from: "start",
                    amount: 0.25,
                }
            }, "-=.10")
            .to(closeButton, {
                y: "-20px",
                duration: 0.25,
                ease: "power2.inOut",
            });
    }, []);

    useEffect(() => {
        if (isMenuOpen) {
            menuTl.current.reverse();
            setIsMenuOpen(false);
        }
    }, [location]);

    function openMenu() {
        if (menuTl.current) {
            if (!isMenuOpen) {
                menuTl.current.play();
                setIsMenuOpen(true);
            } else {
                menuTl.current.reverse();
                setIsMenuOpen(false);
            }
        }
    }

    return (
        <div className="nav-bar">
            <div className="nav-bar-title" ref={navBarTitleRef}>
                <div className="text-regular">
                    Luka Kolanovic
                </div>
                <div className="text-regular text-is-gray">
                    Photographer
                </div>
            </div>
            <div className='menu-button-container'>
                <div className='menu-button' onClick={openMenu} ref={menuButtonRef}>Menu</div>
                <div className='menu-button text-small text-is-gray' onClick={openMenu} ref={closeButtonRef}>Close</div>
            </div>
            <div className='nav-menu' ref={navMenuRef}>
                <div className="nav-menu-inner">
                    <div>
                        <div className="text-small text-is-gray">Navigation</div>
                        <div className="line-is-gray"></div>
                    </div>
                    <div>
                        <div className="title-nav is-link menu-links" ref={el => navTextAnimationRefs.current[0] = el}>
                            <Link to="./">Home</Link>
                        </div>
                        <div className="title-nav is-link menu-links" ref={el => navTextAnimationRefs.current[1] = el}>
                            <Link to="/work">Work</Link>
                        </div>
                        <div className="title-nav is-link menu-links" ref={el => navTextAnimationRefs.current[2] = el}>
                            <Link to="/about">About</Link>
                        </div>
                        <div className="title-nav is-link menu-links" ref={el => navTextAnimationRefs.current[3] = el}>
                            <Link to="/contact">Contact</Link>
                        </div>
                    </div>
                    <div className='nav-socials'>
                        <div className="text-small text-is-gray">Socials</div>
                        <div className="nav-socials-inner">
                            <div className="text-small is-link">
                                <div className="underline-wrapper">
                                    <p className='text-is-black'>Instagram</p>
                                    <div className="bg-is-grey underline-effect"></div>
                                </div>
                            </div>
                            <div className="text-small is-link">
                                <div className="underline-wrapper is-link">
                                    <p className='text-is-black'>LinkedIn</p>
                                    <div className="bg-is-grey underline-effect"></div>
                                </div>
                            </div>
                            <div className="text-small is-link">
                                <div className="underline-wrapper">
                                    <p className='text-is-black'>Twitter</p>
                                    <div className="bg-is-grey underline-effect"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
