import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import client from '../../sanityClient';
import { gsap } from 'gsap';
import './img-grid.css';

function ImgGrid({ activeFilter }) {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [renderedPosts, setRenderedPosts] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {
        client.fetch(`
            *[_type == "post"]{
                title,
                slug,
                coverImage{asset->{url}},
                location,
                publishedAt,
                images{asset->{url}},
                tag
            }
        `)
            .then((data) => {
                setPosts(data);
                setFilteredPosts(data);
                setRenderedPosts(data); // Initiale Anzeige
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (activeFilter === 'all') {
            setFilteredPosts(posts);
        } else {
            setFilteredPosts(posts.filter((post) => post.tag === activeFilter));
        }
    }, [activeFilter, posts]);

    useEffect(() => {
        if (gridRef.current) {
            const gridElement = gridRef.current;

            // Schritt 1: Container ausblenden
            gsap.to(gridElement, { opacity: 0, duration: 0.3 });

            // Schritt 2: Posts aktualisieren
            setTimeout(() => {
                setRenderedPosts(filteredPosts);

                setTimeout(() => {
                    gsap.to(gridElement, { opacity: 1, duration: 0.5 });
                }, 350);
            }, 300);
        }
    }, [filteredPosts]);


    const renderPattern = () => {
        const elements = [];
        for (let i = 0; i < renderedPosts.length; i += 6) {
            elements.push(
                <div className="gallery-element-type-two" key={`group-2-${i}`}>
                    {renderedPosts.slice(i, i + 2).map((post, index) => (
                        <Link to={`/gallery/${post.slug.current}`} key={`post-${i + index}`}>
                            <div className="divider">
                                <div className="divider-title">
                                    <div className="text-regular">{post.title}</div>
                                </div>
                                <div className="divider-img">
                                    <img src={post.coverImage?.asset?.url} alt={post.title} />
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            );

            elements.push(
                <div className="gallery-element-type-one" key={`group-1-${i}`}>
                    {renderedPosts[i + 2] && (
                        <Link to={`/gallery/${renderedPosts[i + 2].slug.current}`} key={`post-${i + 2}`}>
                            <div className="divider">
                                <div className="divider-title">
                                    <div className="text-regular">{renderedPosts[i + 2].title}</div>
                                </div>
                                <div className="divider-img">
                                    <img src={renderedPosts[i + 2].coverImage?.asset?.url} alt={renderedPosts[i + 2].title} />
                                </div>
                            </div>
                        </Link>
                    )}
                </div>
            );

            elements.push(
                <div className="gallery-element-type-three" key={`group-3-${i}`}>
                    {renderedPosts.slice(i + 3, i + 6).map((post, index) => (
                        <Link to={`/gallery/${post.slug.current}`} key={`post-${i + 3 + index}`}>
                            <div className="divider">
                                <div className="divider-title">
                                    <div className="text-regular">{post.title}</div>
                                </div>
                                <div className="divider-img">
                                    <img src={post.coverImage?.asset?.url} alt={post.title} />
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            );
        }
        return elements;
    };

    return (
        <div className="gallery-container" ref={gridRef}>
            {renderPattern()}
        </div>
    );
}

export default ImgGrid;
